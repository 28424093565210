<template>
  <div>
    <div class="banner_info" style="background: url(images/5_02.jpg) center no-repeat;background-size: cover;">
      <img src="images/5_03.png">
    </div>
    <div class="infopage">
      <div class="container">
        <certification />
      </div>
      <div class="container clearfix">
        <div class="right right_lg">
          <div class="box">
            <div class="title flex">
              <div class="flex_item"><b>证书公告</b></div>
            </div>
            <div class="bd">
              <ul class="newslist" v-if="columnData.articleList && columnData.articleList.length>0">
                <li v-for="item in columnData.articleList" :key="item.id">
                  <div class="flex_item" @click="toDetail(item.id,columnData.name)"><a href="javascript:;">{{item.title}}</a></div>
                  <span>{{item.publishTime}}</span>
                </li>
              </ul>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[20, 40, 60, 80]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import certification from "@/components/certification";
export default {
  name: "certificate",
  components:{
    certification
  },
  data(){
    return {
      columnData:[],
      pageNum:1,
      pageSize:20,
      total:0
    }
  },
  mounted() {
    this.findColumnData()
  },
  methods:{
    async findColumnData(){
      let res = await this.$api.findData({columnName:'证书公告',page:this.pageNum,size:this.pageSize});
      this.columnData = res.data.data || [];
      this.total = res.data.data.totalSize
      console.log(this.columnData,'--');
    },
    handleSizeChange(val){
      this.pageSize = val
      this.findColumnData()
    },
    handleCurrentChange(val){
      this.pageNum = val
      this.findColumnData()
    },
    toDetail(id,name){
      this.$router.push({path:'/view',query:{articleId:id,name:name}})
    },
    toMore(cid){
      this.$router.push({path:'/list',query:{pid:cid}})
    },
  }
}
</script>

<style scoped>

</style>
